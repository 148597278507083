import React from 'react'
import PropTypes from 'prop-types'

const Offerings = ({ gridItems }) => (
  <div className='columns is-multiline'>
    {gridItems.map(item => (
      <div key={item.image} className='column is-6' style={{ borderRadius: '5px' }}>
        <section className='section'>
          <p className='has-text-centered'>
            <img alt='' src={item.image} />
             <h2 className="magento-details-title">{item.title}</h2>
          </p>
          <div className='content' dangerouslySetInnerHTML={{__html: item.text}}></div>
        </section>
      </div>
    ))}
  </div>
)

Offerings.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

export default Offerings
