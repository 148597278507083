import React from 'react'
import Helmet from 'react-helmet'
import Offerings from '../Offerings'
import Testimonials from '../Testimonials'
import ComingSoons from '../ComingSoons'
import QuestionsCard from '../Bbs/questions'
// import RecentPosts, {PostLink} from '../Bbs/question/recent-posts'
import PropTypes from 'prop-types'

// const StyledRecentPosts = styled(RecentPosts)({
//   marginBottom: 90
// });
const HomePageTemplate = ({
  title,
  title_description,
  heading,
  description,
  offerings,
  meta_title,
  meta_description,
  testimonials,
  comingsoons,
}) => (
  <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>
    <section className='homepage hero is-primary is-bold is-medium'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='section'>
                <h1 className='title' dangerouslySetInnerHTML={{__html: title}}></h1>
                <div className='content' dangerouslySetInnerHTML={{__html: title_description}}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className='section section--gradient'>
      <div className='container'>

        <div className='section'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='content'>
                <div>
                  <h3 className='has-text-weight-semibold is-size-2'>
                    {heading}
                  </h3>
                  <p>{description}</p>
                </div>
                <Offerings gridItems={offerings.blurbs} />
                <ComingSoons comingsoons={comingsoons} />
                <h2 className='has-text-weight-semibold is-size-2'>客户感言</h2>
                <Testimonials testimonials={testimonials} />
                <h2 className='has-text-weight-semibold is-size-2'>问答社区</h2>
                <QuestionsCard/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  title_description: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  testimonials: PropTypes.array,
  comingsoons: PropTypes.array
}

export default HomePageTemplate
