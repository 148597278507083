import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby'

function useFetch(url) {
    const [data, setData] = useState(null);
    useEffect(() => {
        async function loadData() {
            const response = await fetch(url);
            if(!response.ok) {
                // oups! something went wrong
                return;
            }
    
            const posts = await response.json();
            setData(posts);
        }
    
        loadData();
    }, [url]);
    return data;
}

export default function QuestionsCard() {
    const posts = useFetch('https://bbs.mallol.cn/wp-json/wp/v2/questions');
  return (
    <div className='container'>
      {posts && posts.map((post, index) => (
        <div
            className='content'
            style={{ border: '1px solid #eaecee', padding: '1em 2em' }}
            key={post.id}
          >
            <p>
              <Link target="_blank" className='has-text-primary' to={post.url}>
                 <span dangerouslySetInnerHTML={{__html: post.title}}></span>
              </Link>
              <span> &bull; </span>
              <small>{post.date}</small>
            </p>
            <div
              className="post__excerpt"
              dangerouslySetInnerHTML={{ __html: post.excerpt }}
            />
            <p>
              <br />
              <Link target="_blank" className='button is-small' to={post.url}>
                                回复 →
              </Link>
            </p>
          </div>
     ))}
    </div>
 );
}