import React from 'react'
import PropTypes from 'prop-types'

const ComingSoons = ({ comingsoons }) => (
  <div>
    {comingsoons.map((comingsoon, id) => (
      <article className='message' key={id}>
        <div className='feature coming-soon'>
          <h2 className='magento-details-title' dangerouslySetInnerHTML={{__html: comingsoon.title}}></h2>
          <p className='lead' dangerouslySetInnerHTML={{__html: comingsoon.lead}}></p>
          <p className='quote' dangerouslySetInnerHTML={{__html: comingsoon.quote}}></p>
        </div>
      </article>
    ))}
  </div>
)

ComingSoons.propTypes = {
  comingsoons: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      title: PropTypes.string,
      lead: PropTypes.string
    })
  ),
}

export default ComingSoons
